import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ArticleTemplate = ({
  content,
  contentComponent,
  featuredImage,
  tags,
  endnotes,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content
  return (
    <section className="article-container">
      {helmet || ''}
      <h1 className="article-title">{title}</h1>
      <PreviewCompatibleImage
        imageInfo={{
          image: featuredImage,
          alt: `featured image thumbnail for post ${title}`
        }}
      />
      <PostContent className="article-content" content={content} />
      {endnotes && endnotes.length ? (
        <div className="endnotes-container">
          <ol className="endnotes-list">
            {endnotes.map(endnote => (
              <li className="endnote" key={endnote.note}>
                {endnote.note}
              </li>
            ))}
          </ol>
        </div>
      ) : null}
      {tags && tags.length ? (
        <div style={{ marginTop: '4rem' }}>
          <h4>Tags</h4>
          <ul className="taglist">
            {tags.map(tag => (
              <li key={tag + 'tag'}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <div className="article-bottom-spacer"></div>
    </section>
  )
}

ArticleTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  featuredImage: PropTypes.object,
  tags: PropTypes.array,
  title: PropTypes.string,
  helmet: PropTypes.object,
  endnotes: PropTypes.node
}

const Article = ({ data }) => {
  const { markdownRemark: post } = data
  const featuredImageURL =
    post.frontmatter.featuredimage.childImageSharp.fluid.src
  return (
    <Layout activePage="article">
      <ArticleTemplate
        content={post.html}
        contentComponent={HTMLContent}
        featuredImage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="%s | Article">
            <title>{`${post.frontmatter.title}`}</title>
            {/*  Article level SEO description todo
            /> */}
            <meta
              name="twitter:title"
              content={post.frontmatter.title || 'Article'}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description || ''}
              i
            />
            <meta name="twitter:image" content={featuredImageURL} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        endnotes={post.frontmatter.endnotes}
      />
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  tags: PropTypes.array
}

export default Article

export const pageQuery = graphql`
  query ArticleById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredpost
        tags
        endnotes {
          note
        }
      }
    }
  }
`
